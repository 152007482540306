import { CustomModalErrors } from "@components/CustomModalErrors"
import CustomProTable from "@components/CustomProTable"
import { LayoutAdmin } from "@components/LayoutAdmin"
import { useStores } from "@hooks/use-stores"
import { AffiliatedStatus, AffiliatedType } from "@modules/Affiliated/store"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import { Button, Col } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useCallback, useEffect, useRef, useState } from "react"

import styles from "./style.module.less"

const Creators = () => {
  const [errors, setErrors] = useState([])
  const [isClient, setIsClient] = useState(false)
  const [data, setData] = useState([])
  const [totalData, setTotalData] = useState([])

  const actionRef = useRef(null)
  const { affiliated } = useStores()

  const { findAll, editProfileCreatorAdmin } = affiliated

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleFindAllCreators = useCallback(async (params = {}) => {
    params.orderBy = "name"

    const response = await findAll(params)

    const newResponse = _get(response, "data", [])
    const total = _get(response, "total", [])

    setData(newResponse)
    setTotalData(total)
  }, [])

  const handleStatusCreator = async (id, status) => {
    const creator = data.find((item) => item.id === id)
    creator.status = status

    const response = await editProfileCreatorAdmin(creator.id, creator)

    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    handleFindAllCreators()
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      className: "drag-visible",
      key: "id",
      align: "center",
      width: "70px",
      hideInSearch: true,
    },
    {
      title: "Tipo",
      dataIndex: "type",
      className: "drag-visible",
      key: "type",
      align: "center",
      width: "80px",
      valueEnum: {
        1: { text: "Modelo", value: AffiliatedType.MODEL },
        2: { text: "Fotógrafo", value: AffiliatedType.PHOTOGRAFER },
      },
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Nome",
      dataIndex: "name",
      className: "drag-visible",
      key: "name",
      align: "center",
      render: (__text, { id }) => {
        const path = `/perfil/${id}`
        return (
          <a href={path} target="_blank" rel="noreferrer">
            <span className="normal pointer french-rose">{__text}</span>
          </a>
        )
      },
    },
    {
      title: "Apelido",
      dataIndex: "nickname",
      className: "drag-visible",
      key: "nickname",
      align: "center",
    },
    {
      title: "Telefone",
      dataIndex: "phoneNumber",
      className: "drag-visible",
      key: "phone",
      align: "center",
      width: "150px",
      hideInSearch: true,
    },
    {
      title: "Ensaios",
      dataIndex: "totalEssay",
      className: "drag-visible",
      key: "essay",
      align: "center",
      width: "80px",
      hideInSearch: true,
    },
    {
      title: "Postagens",
      dataIndex: "totalPost",
      className: "drag-visible",
      key: "post",
      align: "center",
      width: "80px",
      hideInSearch: true,
    },
    {
      title: "Seguidores",
      dataIndex: "totalFollowers",
      className: "drag-visible",
      key: "followers",
      align: "center",
      width: "80px",
      hideInSearch: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "drag-visible",
      key: "status",
      align: "center",
      width: "70px",
      hideInSearch: true,
      render: (type) => (
        <div className="flex flex-row justify-center">
          <div
            className={
              type.props.text === 1
                ? cx("br-100 mr8", styles.active)
                : cx("br-100 mr8", styles.inactive)
            }
          />
          <label className="normal pointer">{type}</label>
        </div>
      ),
      valueEnum: {
        1: { text: "Ativo", value: AffiliatedStatus.ACTIVE },
        2: { text: "Inativo", value: AffiliatedStatus.INACTIVE },
      },
    },
    {
      title: "",
      dataIndex: "actions",
      className: "drag-visible",
      key: "actions",
      width: "100px",
      align: "center",
      hideInSearch: true,
      render: (text, { id, status }) =>
        status === 1 ? (
          <div>
            <Button
              className="button-inactive"
              style={{ width: "100px" }}
              onClick={() => handleStatusCreator(id, 2)}
            >
              Desativar
            </Button>
          </div>
        ) : (
          <div>
            <Button
              type="primary"
              className="button-active"
              style={{ width: "100px" }}
              onClick={() => handleStatusCreator(id, 1)}
            >
              Aprovar
            </Button>
          </div>
        ),
    },
  ]

  if (!isClient) return null

  return (
    <LayoutAdmin
      title="Criadores"
      subTitle="Visualize e gerencie os criadores."
    >
      <Col span={24}>
        <div className="creators-table">
          <CustomProTable
            actionRef={actionRef}
            headerTitle="Listagem de criadores"
            columns={columns}
            request={handleFindAllCreators}
            dataSource={data}
            toolBarRender={() => [
              <Button key="button" type="primary" className="button-creator">
                Cadastrar criador
              </Button>,
            ]}
            rowKey={(record) => record.id}
            pagination={{
              total: totalData,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showTotal: (total) => {
                if (total > 0) {
                  return `Total ${total} ${total > 1 ? "itens" : "item"}`
                }

                return null
              },
            }}
          />

          {errors.length ? (
            <CustomModalErrors errors={errors} setErrors={setErrors} />
          ) : null}
        </div>
      </Col>
    </LayoutAdmin>
  )
}

export default Creators
